<template>
  <Gallery
    oncontextmenu="return false;"
    v-if="data != null"
    noOverlay
    dense
    :data="data"
  >
    <template v-slot:content="{data, hover}">
      <div
        :key="data.loading"
        v-on:click="openProjectEvent(data.id)"
        style="height: 100%; width: 100%; position: absolute"
        class="tile"
      >
        <!-- background area -->
        <div class="tile-background">
          <div v-show="data.archived" class="archive-marker">
            <v-icon color="white" class="center" size="19">mdi-archive</v-icon>
          </div>

          <div
            v-if="
              !(
                $store.getters.user != null &&
                $store.getters.user.internal == true
              ) &&
              data.loading == true &&
              data.preview == null
            "
            class="center-offset"
          >
            <v-progress-circular
              color="#C5C5C5"
              :size="30"
              :width="3"
              indeterminate
            ></v-progress-circular>
          </div>
          <div
            v-else-if="
              !(
                $store.getters.user != null &&
                $store.getters.user.internal == true
              ) && data.preview == null
            "
            class="center-offset"
          >
            <span class="grey--text" style="font-size: 14px">{{
              $t('tools.project.overview.noPreview.internal')
            }}</span>
          </div>
          <div v-else>
            <v-img class="image" preload :src="data.preview"></v-img>
          </div>
        </div>

        <div
          :style="
            hover
              ? 'opacity: 1; height: 95px; transition: all 50ms linear '
              : 'opacity: 1; height: 40px; transition: all 50ms linear;  '
          "
          class="text-area"
        >
          <v-row
            class="ma-0 ml-3 mt-1 mr-3"
            align="center"
            style="height: 30px !important"
          >
            <b v-if="data.name != null" :class="hover ? 'primary--text' : ''">
              {{ $helper.shortenText(data.name, 33) }}
            </b>
            <span v-else :class="hover ? 'primary--text' : ''"> - </span>
            <v-spacer />

            <v-fade-transition>
              <v-icon v-show="hover" color="primary" size="20"
                >mdi-chevron-right</v-icon
              >
            </v-fade-transition>
          </v-row>

          <div style="pointer-events: none">
            <div
              style="
                font-size: 14px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 92%;
              "
              class="ml-3 black--text font-weight-medium mb-1"
            >
              {{ data.streetName }}, {{ data.zipCode }} {{ data.city }}
            </div>

            <v-chip
              v-if="data.allowParkControl == true"
              small
              style="background-color: #f0f0f0 !important"
              class="font-weight-bold ml-2"
              >{{ $t('helper.products.gateReport') }}</v-chip
            >
            <v-chip
              v-if="data.allowArming == true"
              small
              style="background-color: #f0f0f0 !important"
              class="font-weight-bold ml-2"
              >{{ $t('helper.products.arming') }}</v-chip
            >
            <v-chip
              v-if="data.allowRecordings == true"
              small
              style="background-color: #f0f0f0 !important"
              class="font-weight-bold ml-2"
              >{{ $t('helper.products.recordings') }}</v-chip
            >

            <!-- <div class="mt-4 ml-3" style="font-size: 14px;">{{ $helper.formatDate(data.createdAt, false,
                                false)
                        }}</div> -->
          </div>
          <div></div>
        </div>
      </div>
    </template>
  </Gallery>
</template>

<script>
import Gallery from '@components/list/Gallery.vue';

/**
 * @group Project
 *
 * Gallery style overview of all projects with preview images. An event is fired when project tile is
 * clicked, to open project page in parent.
 *
 */
export default {
  name: 'ProjectGallery',
  props: {
    // project data as array from vuex store (user.meta)
    data: {
      required: true,
    },
  },

  components: {
    Gallery,
  },

  methods: {
    openProjectEvent(projectId) {
      // fires when tile is clicked
      this.$emit('open', projectId);
    },
  },
};
</script>

<style scoped>
.tile {
  user-select: none !important;
  overflow: hidden !important;
}

.tile-background {
  overflow: hidden !important;
  border-radius: 5px;
  height: 100%;
  width: 100%;
}

.archive-marker {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(150, 150, 150, 1);
  width: 30px;
  height: 30px;
  border-radius: 5px;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center-offset {
  position: absolute;
  top: calc(50% - 7px);
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-area {
  user-select: none !important;
  position: absolute;
  cursor: pointer !important;
  bottom: -1px;
  z-index: 10;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: rgba(230, 230, 230, 0.9);
  height: 35px;
}

.image {
  user-drag: none !important;
  user-select: none !important;
}
</style>
