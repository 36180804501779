<template>
    <div>
        <!-- Toolbar -->
        <div style="position: relative; width: 100%; height: 55px !important">
            <v-row class="ma-0 mr-4 ml-4" style="
                      position: absolute;
                      top: 50%;
                      transform: translateY(-50%);
                      width: 100%;
                    " align="center">
                <img v-if="$vuetify.breakpoint.xsOnly&&!$vuetify.breakpoint.smOnly"
                    v-on:click="$router.push($auth.landingPage())"
                    style="cursor: pointer !important; user-select: none; user-drag: none" preload width="42" height="42"
                    src="@/assets/logo/alinocam/icon_logo.webp" />

                <img v-else v-on:click="$router.push($auth.landingPage())"
                    style="cursor: pointer !important; user-select: none; user-drag: none" preload width="200"
                    src="@/assets/logo/alinocam/text_medium.webp" />
                <v-spacer />

                <IconButton v-show="
                    $store.getters.user!=null&&$store.getters.user.internal==true
                " icon="mdi-tune-variant" color="primary" buttonColor="primary"
                    style="border-radius: 50%; border: 1px solid #c0c0c0" v-on:click="openAdminPage()"
                    :tooltip="$t('tools.project.overview.employeeArea')" class="mr-4" />

                <!-- Search bar (hidden on phones) -->
                <TextInput icon="mdi-magnify" v-show="
                    !$vuetify.breakpoint.xsOnly&&
                    $store.getters.user!=null&&
                    $store.getters.user.internal==true
                " :value="searchValue" v-on:change="searchValue=$event"
                    :placeholder="$t('tools.project.overview.search')" style="max-width: 300px" />

                <v-divider v-show="
                    $store.getters.user!=null&&$store.getters.user.internal==true
                " vertical class="mr-3 ml-3 mt-1 mb-1" />
                <IconButton class="mr-8" v-on:click="$router.push({ name: 'login' })" icon="mdi-location-exit" />
            </v-row>
        </div>

        <v-divider class="ml-3 mr-3" />

        <div v-if="projects==null" class="loading-area">
            <LoadingProjectData class="center" text />
        </div>
        <div v-else class="pt-2 pl-3 pr-3 gallery-area">
            <ProjectGallery v-on:open="openProjectPage" :data="dataHandle.filter((project) => !project.archived)" />

            <div v-show="
                dataHandle!=null&&
                dataHandle.filter((project) => project.archived).length>0
            " class="mt-10 mb-2 font-weight-bold" style="font-size: 17px">
                {{$t('tools.project.overview.finishedProjects')}}
            </div>

            <ProjectGallery class="pb-4" v-on:open="openProjectPage"
                :data="dataHandle.filter((project) => project.archived)" />
        </div>
    </div>
</template>

<script>
import LoadingProjectData from '@components/loading/LoadingProjectData.vue';
import ProjectGallery from '@/components/tools/project/overview/ProjectGallery.vue';
import IconButton from '@components/button/IconButton.vue';
import TextInput from '../../../../ui-components/src/components/text/TextInput.vue';

// @group Project
export default {
    name: 'ProjectViewOverview',
    components: {
        LoadingProjectData,
        ProjectGallery,
        IconButton,
        TextInput,
    },

    data() {
        return {
            dataHandle: [],
            searchValue: '',
        };
    },

    async created() {
        // checking whether user meta data have been requested; if not data is requested and added to vuex!
        if (
            this.$store.getters.user == null ||
            this.$store.getters.user.projects == null ||
            this.$store.getters.user.projects.length == 0
        ) {
            try {
                // get user information (meta) and user projects simultanously
                var userResp = await Promise.all([
                    this.$api.user.user(this.$store.getters.currentUser.id),
                    this.$api.user.projects(),
                ]);

                // userResp = null
                // check if request was successfull & required data is present --> update vuex store data
                if (
                    !userResp.some((resp) => resp.success == false) &&
                    userResp[0].data != null &&
                    userResp[1].data != null
                ) {
                    this.$store.commit('updateCurrentUserMeta', {
                        ...userResp[0].data,
                        internal: userResp[0].data.type == 1,
                        projects: userResp[1].data.projects || [],
                    });
                }
            } catch (error) {
                return this.$router.push({
                    name: 'login',
                    query: { type: 'reload', route: this.$route.path, id: 8 },
                }); // automatic logout
            }
        }

        if (this.$store.getters.user == null)
            return this.$router.push({
                name: 'login',
                query: { type: 'reload', route: this.$route.path, id: 10 },
            }); // automatic logout
    },

    computed: {
        projects() {
            if (this.$store.getters.user == null) return;

            if (this.searchValue == null || this.searchValue.length == 0)
                return this.$store.getters.user.projects;
            else
                return this.$store.getters.user.projects.filter((project) =>
                    project.name.toLowerCase().includes(this.searchValue.toLowerCase()),
                );
        },

        data() {
            if (this.dataHandle == null) return [];

            if (this.searchValue != null && this.searchValue.length > 0) {
                return this.dataHandle.filter((p) =>
                    p.name.toUpperCase().includes(this.searchValue.toUpperCase()),
                );
            }

            return this.dataHandle;
        },
    },

    watch: {
        projects: {
            handler() {
                if (this.projects != null) return this.getProjects();
            },
            immediate: true,
            deep: true,
        },
    },

    methods: {
        openAdminPage() {
            window.open(`https://admin.alinocam.com`, '_blank')
        },
        searchDataEvent(searchString) {
            this.searchValue = searchString;
        },

        openProjectPage(projectId) {
            var projectHandle = this.dataHandle.reduce((a, b) =>
                a.id == projectId ? a : b,
            );
            if (
                projectHandle == null ||
                projectHandle == false ||
                projectHandle.id != projectId
            ) {
                return this.$toast.error('Projekt konnte nicht geöffnet werden');
            }

            return this.$helper.openClientProject(projectHandle);
        },

        updateRequestEvent() {
            this.getProjects();
        },

        async getProjectPresets(projectId) {
            if (
                projectId == null ||
                (this.$store.getters.user != null &&
                    this.$store.getters.user.internal == true)
            )
                return;
            try {
                const projectObjIndex = this.dataHandle.findIndex(
                    (project) => project.id == projectId,
                );
                if (projectObjIndex == -1) return;

                const resp = await this.$api.project.latestImages(projectId, false);

                this.dataHandle[projectObjIndex].loading = false;
                this.dataHandle[projectObjIndex].preview =
                    resp[0].thumbnail.downloadUrl || null;

                // return {...this.dataHandle[projectObjIndex], preview: resp[0].thumbnail.downloadUrl || null}
            } catch {
                return;
            }
        },

        async getProjects() {
            if (this.projects == null) return;

            if (this.projects.length == 0) {
                this.dataHandle = [];
            } else {
                this.dataHandle = this.projects.map((project) => {
                    return { ...project, loading: true };
                });
                await Promise.all(
                    this.projects.map((project) =>
                        this.getProjectPresets(project.id || null),
                    ),
                );
            }
        },
    },
};
</script>

<style>
.tab-container {
    position: relative;
    height: calc(100vh - 110px);
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loading-area {
    position: relative;
    height: 100%;
    overflow: hidden !important;
}

.gallery-area {
    user-select: none !important;
    height: calc(100vh - 50px);
    background-color: transparent;
    overflow-y: auto;
    overflow-x: hidden !important;
}
</style>
