var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"position":"relative","width":"100%","height":"55px !important"}},[_c('v-row',{staticClass:"ma-0 mr-4 ml-4",staticStyle:{"position":"absolute","top":"50%","transform":"translateY(-50%)","width":"100%"},attrs:{"align":"center"}},[(_vm.$vuetify.breakpoint.xsOnly&&!_vm.$vuetify.breakpoint.smOnly)?_c('img',{staticStyle:{"cursor":"pointer !important","user-select":"none","user-drag":"none"},attrs:{"preload":"","width":"42","height":"42","src":require("@/assets/logo/alinocam/icon_logo.webp")},on:{"click":function($event){_vm.$router.push(_vm.$auth.landingPage())}}}):_c('img',{staticStyle:{"cursor":"pointer !important","user-select":"none","user-drag":"none"},attrs:{"preload":"","width":"200","src":require("@/assets/logo/alinocam/text_medium.webp")},on:{"click":function($event){_vm.$router.push(_vm.$auth.landingPage())}}}),_c('v-spacer'),_c('IconButton',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.$store.getters.user!=null&&_vm.$store.getters.user.internal==true
            ),expression:"\n                $store.getters.user!=null&&$store.getters.user.internal==true\n            "}],staticClass:"mr-4",staticStyle:{"border-radius":"50%","border":"1px solid #c0c0c0"},attrs:{"icon":"mdi-tune-variant","color":"primary","buttonColor":"primary","tooltip":_vm.$t('tools.project.overview.employeeArea')},on:{"click":function($event){return _vm.openAdminPage()}}}),_c('TextInput',{directives:[{name:"show",rawName:"v-show",value:(
                !_vm.$vuetify.breakpoint.xsOnly&&
                _vm.$store.getters.user!=null&&
                _vm.$store.getters.user.internal==true
            ),expression:"\n                !$vuetify.breakpoint.xsOnly&&\n                $store.getters.user!=null&&\n                $store.getters.user.internal==true\n            "}],staticStyle:{"max-width":"300px"},attrs:{"icon":"mdi-magnify","value":_vm.searchValue,"placeholder":_vm.$t('tools.project.overview.search')},on:{"change":function($event){_vm.searchValue=$event}}}),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.$store.getters.user!=null&&_vm.$store.getters.user.internal==true
            ),expression:"\n                $store.getters.user!=null&&$store.getters.user.internal==true\n            "}],staticClass:"mr-3 ml-3 mt-1 mb-1",attrs:{"vertical":""}}),_c('IconButton',{staticClass:"mr-8",attrs:{"icon":"mdi-location-exit"},on:{"click":function($event){return _vm.$router.push({ name: 'login' })}}})],1)],1),_c('v-divider',{staticClass:"ml-3 mr-3"}),(_vm.projects==null)?_c('div',{staticClass:"loading-area"},[_c('LoadingProjectData',{staticClass:"center",attrs:{"text":""}})],1):_c('div',{staticClass:"pt-2 pl-3 pr-3 gallery-area"},[_c('ProjectGallery',{attrs:{"data":_vm.dataHandle.filter(function (project) { return !project.archived; })},on:{"open":_vm.openProjectPage}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.dataHandle!=null&&
            _vm.dataHandle.filter(function (project) { return project.archived; }).length>0
        ),expression:"\n            dataHandle!=null&&\n            dataHandle.filter((project) => project.archived).length>0\n        "}],staticClass:"mt-10 mb-2 font-weight-bold",staticStyle:{"font-size":"17px"}},[_vm._v(" "+_vm._s(_vm.$t('tools.project.overview.finishedProjects'))+" ")]),_c('ProjectGallery',{staticClass:"pb-4",attrs:{"data":_vm.dataHandle.filter(function (project) { return project.archived; })},on:{"open":_vm.openProjectPage}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }