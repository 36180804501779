var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data != null)?_c('Gallery',{attrs:{"oncontextmenu":"return false;","noOverlay":"","dense":"","data":_vm.data},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var data = ref.data;
var hover = ref.hover;
return [_c('div',{key:data.loading,staticClass:"tile",staticStyle:{"height":"100%","width":"100%","position":"absolute"},on:{"click":function($event){return _vm.openProjectEvent(data.id)}}},[_c('div',{staticClass:"tile-background"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(data.archived),expression:"data.archived"}],staticClass:"archive-marker"},[_c('v-icon',{staticClass:"center",attrs:{"color":"white","size":"19"}},[_vm._v("mdi-archive")])],1),(
            !(
              _vm.$store.getters.user != null &&
              _vm.$store.getters.user.internal == true
            ) &&
            data.loading == true &&
            data.preview == null
          )?_c('div',{staticClass:"center-offset"},[_c('v-progress-circular',{attrs:{"color":"#C5C5C5","size":30,"width":3,"indeterminate":""}})],1):(
            !(
              _vm.$store.getters.user != null &&
              _vm.$store.getters.user.internal == true
            ) && data.preview == null
          )?_c('div',{staticClass:"center-offset"},[_c('span',{staticClass:"grey--text",staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.$t('tools.project.overview.noPreview.internal')))])]):_c('div',[_c('v-img',{staticClass:"image",attrs:{"preload":"","src":data.preview}})],1)]),_c('div',{staticClass:"text-area",style:(hover
            ? 'opacity: 1; height: 95px; transition: all 50ms linear '
            : 'opacity: 1; height: 40px; transition: all 50ms linear;  ')},[_c('v-row',{staticClass:"ma-0 ml-3 mt-1 mr-3",staticStyle:{"height":"30px !important"},attrs:{"align":"center"}},[(data.name != null)?_c('b',{class:hover ? 'primary--text' : ''},[_vm._v(" "+_vm._s(_vm.$helper.shortenText(data.name, 33))+" ")]):_c('span',{class:hover ? 'primary--text' : ''},[_vm._v(" - ")]),_c('v-spacer'),_c('v-fade-transition',[_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],attrs:{"color":"primary","size":"20"}},[_vm._v("mdi-chevron-right")])],1)],1),_c('div',{staticStyle:{"pointer-events":"none"}},[_c('div',{staticClass:"ml-3 black--text font-weight-medium mb-1",staticStyle:{"font-size":"14px","text-overflow":"ellipsis","overflow":"hidden","white-space":"nowrap","width":"92%"}},[_vm._v(" "+_vm._s(data.streetName)+", "+_vm._s(data.zipCode)+" "+_vm._s(data.city)+" ")]),(data.allowParkControl == true)?_c('v-chip',{staticClass:"font-weight-bold ml-2",staticStyle:{"background-color":"#f0f0f0 !important"},attrs:{"small":""}},[_vm._v(_vm._s(_vm.$t('helper.products.gateReport')))]):_vm._e(),(data.allowArming == true)?_c('v-chip',{staticClass:"font-weight-bold ml-2",staticStyle:{"background-color":"#f0f0f0 !important"},attrs:{"small":""}},[_vm._v(_vm._s(_vm.$t('helper.products.arming')))]):_vm._e(),(data.allowRecordings == true)?_c('v-chip',{staticClass:"font-weight-bold ml-2",staticStyle:{"background-color":"#f0f0f0 !important"},attrs:{"small":""}},[_vm._v(_vm._s(_vm.$t('helper.products.recordings')))]):_vm._e()],1),_c('div')],1)])]}}],null,false,518448934)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }